@charset "utf-8";

.brandNav {
	display: flex;
	justify-content: center;
	margin-top: 38px;

	&__item {
		&:first-child {
			margin-right: 39px;
		}
	}
}

.contentsWrap {
	min-height: 100%;
	box-sizing: border-box;
	position: relative;
}

#mainArea {
	padding-bottom: 105px;
}

footer {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	text-align: center;
}